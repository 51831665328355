import { useEffect, useRef, useState } from "react";

const Sky = ({tod="night", callback}:{tod?:string, callback:any}) => {
    const stars = useRef<number>(70);
    const clouds = useRef<number>(4)
    const [sky, setSky] = useState<JSX.Element[]>([]);
    const timeOfDay = useRef<string>(tod)

    // Function to generate stars
    function generateStars() {
        const starContainer: JSX.Element[] = [];
        for (let i = 0; i < stars.current; i++) {
            const posX = Math.floor(Math.random() * window.innerWidth);
            const posY = Math.floor(Math.random() * ( window.innerHeight * 2 ));
            starContainer.push(
                <div className="star" style={{ left: posX, top: posY }} key={i}>
                    <div className="starpart" id={i.toString()}></div>
                </div>
            );
        }
        setSky(starContainer);
    }

    function generateClouds() {
        const cloudContainer: JSX.Element[] = [];
        for (let i = 0; i < clouds.current; i++) {
            const posX = Math.floor(Math.random() * window.innerWidth);
            const posY = Math.floor(Math.random() * ( window.innerHeight * 2 ));
            let flipper:any = Math.floor(Math.random() * 2)
            flipper = flipper < 1 ? true : false
            cloudContainer.push(
                <>
                    <div className="cloud" style={{ left: posX, top: posY }} key={i}>
                        <div className="cloudpart" id={i.toString()}></div>
                        <div className="cloudpart" id={i.toString()} style={{position: "relative", top: (Math.floor(Math.random() * 80)), left: -40}}></div>
                        <div className="cloudpart" id={i.toString()} style={{position: "relative", top: (Math.floor(Math.random() * 80)), left: -120}}></div>
                        <div className="cloudpart" id={i.toString()} style={{position: "relative", top: (Math.floor(Math.random() * -10)), left: -180}}></div>
                    </div>
                    <div className="cloud" style={{ left: posX + 60 + ( (Math.floor(Math.random() * 20) * (flipper ? -1 : 1)) ), top: posX + ( (Math.floor(Math.random() * 2) * (flipper ? 1 : -1)) ) }} key={i}>
                        <div className="cloudpart" id={i.toString()}></div>
                        <div className="cloudpart" id={i.toString()} style={{position: "relative", top: (Math.floor(Math.random() * 80)), left: -40}}></div>
                        <div className="cloudpart" id={i.toString()} style={{position: "relative", top: (Math.floor(Math.random() * 80)), left: -120}}></div>
                        <div className="cloudpart" id={i.toString()} style={{position: "relative", top: (Math.floor(Math.random() * -10)), left: -180}}></div>
                    </div>
                </>
            );
        }
        setSky(cloudContainer);
    }

    // Function to move the sky
    function moveSky(direction: number = 0) {
        const skyElement = document.getElementById("sky") as HTMLElement;
        if (skyElement) {
            if (direction === 0) {
                skyElement.classList.add("night"); // Add night class to trigger movement
            } else {
                skyElement.classList.remove("night"); // Remove night class to reset
                skyElement.classList.add("day"); // Remove night class to reset
            }
        }
        twinkleStars()
    }

    /**
     * The function `twinkleStars` toggles the "twinkle" class on a randomly selected star element at a
     * set interval.
     */
    function twinkleStars(){
        const twinkleInterval = setInterval(() => {
            const star = document.getElementById(Math.floor(Math.random() * stars.current).toString())
            if ( star ){
                if ( star.classList.contains("twinkle") ){
                    star.classList.remove("twinkle")
                }
                else{
                    star.classList.add("twinkle")
                }

            }
            if ( timeOfDay.current !== "night" ){
                clearInterval(twinkleInterval)
            }
        }, 500)
    }

    useEffect(() => {
        generateStars();
        // Wait for a brief moment before adding the night class to see the transition
        const timer = setTimeout(() => {
            moveSky(0);
        }, 100); // Adjust the delay if necessary

        return () => clearTimeout(timer); // Clean up the timer on unmount
    }, []);

    useEffect(() => {
        if ( tod !== "night"){
            setSky([])
            generateClouds()
            moveSky(1)
            callback("day")
        }
    }, [tod])

    return (
        <div className="skybox">
            <div id="sky" className="sky"> {/* Initial class without night */}
                {sky}
            </div>
        </div>
    );
};

export default Sky;
