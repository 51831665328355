import { useRef, useState } from 'react'
import './Void.css'
import StyledButton from '../_Global/StyledButton'

const Void = () => {
    const [voidText, setVoidText] = useState("Kind of dark down here, huh?")
    const showNext = useRef<boolean>(true)
    const voidPhase = useRef<number>(0)

    function continueTour(){
        switch(voidPhase.current){
            case 0:
                setVoidText("It's ok. This is a safe place!")
                break
            case 1:
                setVoidText("I don't want to take up much more of your time.")
                break
            case 2:
                setVoidText("I greatly appreciate you visiting my place on the internet today.")
                break
            case 2:
                setVoidText("There is so much more we can discuss and I'd be happy to do so!")
                break
            case 3:
                setVoidText("If you have a role you're looking to fill, let's get in touch.")
                break
            case 4:
                setVoidText("yourehired@brandongather.com")
                break
            case 5:
                setVoidText("Cheeky, I know. Again, thank's for visiting today.")
                break
            case 6:
                setVoidText("When you click 'NEXT' this time, you'll be sent to my LinkedIn.")
                break
            case 7:
                window.open("https://linkedin.com/in/brandongather", "_self")
                break
        }
        voidPhase.current++
    }
    
    return (
        <>
            <div className="void">
                {voidText}
            </div>
            { showNext.current ?
                <StyledButton id="main" label="Next" action={continueTour} forcedY='-122vh' />
            :
                <></>
            }
        </>
    )
}

export default Void