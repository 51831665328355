import { useEffect } from "react"
import "./Details.css"

const Details = () => {
    useEffect(() => {
        document.body.style.backgroundColor = "black"
    }, [])

    return (
        <div className="details">
            I thought I'd give your clicker a break :)
            <br />
            <br />
            Try scrolling
        </div>
  )
}

export default Details