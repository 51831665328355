import { ZIndex } from "@tsparticles/engine"

const StyledButton = ({label, id, action, forcedX, forcedY}:{label:string, id:string, action:any, forcedX?:string, forcedY?:string}) => {
    const styledButton:any = {
        position: "absolute",
        bottom: forcedY ?? "2vh",
        right: forcedX ?? "2vw",
        backgroundColor: "rgba(50,125,199,0.75)",
        color: "white",
        padding: "40px",
        paddingTop: "15px",
        paddingBottom: "15px",
        border: "2px solid white",
        borderRadius: "15px",
        cursor: "pointer",
        zIndex: "1000"
    }
    return (
        <button style={styledButton} id={id} onClick={action}>{label}</button>
    )
}

export default StyledButton