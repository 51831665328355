import Details from "../Components/Landing/Details"
import Splash from "../Components/Landing/Splash"
import Void from "../Components/Landing/Void"

const Landing = () => {
  return (
    <>
      <Splash />
      <Details />
      <Void />
    </>
  )
}

export default Landing